<template>
  <div id="tfw-section-7" class="section-7">
    <div class="wrapper mx-auto">
      <div class="wrap mx-auto">
        <h2 class="heading-4">
          TalentFind is the World's First Intelligent<br />
          medical Recruitment Solution
        </h2>
        <div class="subhead">
          It's unique AI Knowledge engine underpins<br />
          TalentFind Marketplace<br />the first technology designed for medical
          recruiters
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4 plain-div-block">
          <img
            src="/img/tfw/5.png"
            sizes="100vw"
            alt=""
            class="tablet-screen-shot"
          />
          <h4>Publish your jobs on TalentFind Marketplace</h4>
          <p class="paragraph-3">
            Promote your organisation globally<br />Publish your jobs as a Pay
            As You Go customer.<br />
            Full TalentFind Users- preferential rates apply!
          </p>
        </div>
        <div class="col-md-4 plain-div-block">
          <img
            src="/img/tfw/6.png"
            sizes="100vw"
            alt=""
            class="tablet-screen-shot"
          />
          <h4>A Global Talent Pool</h4>
          <p class="paragraph-3">
            Access to a pool full of international<br />healthcare
            professionals. <br />Connect and engage in one place.
          </p>
        </div>
        <div class="col-md-4 plain-div-block">
          <img
            src="/img/tfw/7.png"
            sizes="100vw"
            alt=""
            class="tablet-screen-shot"
          />
          <h4>Intelligence Beyond Compare!</h4>
          <p class="paragraph-3">
            100% accuracy matching candidate to jobs<br />Unique Knowledge of
            global medical training,<br />qualifications, regional licensure and
            compliance.
          </p>
        </div>
      </div>
      <CLink
        class="btn btn-register w-button"
        href="https://www.talentfindsolutions.com/"
        target="_blank"
        >Learn more about TalentFind Technology here</CLink
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "TFWSection7",
};
</script>