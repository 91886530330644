<template>
  <div id="tfw-section-6" class="section-6 feature-wrap">
    <div class="feature-wrap full-width">
      <div class="feature-content text-center">
        <h2 class="text-center text-white head-2">
          For Hiring Organisations.<br>Connecting you globally
        </h2>
        <div class="subhead text-center text-white">
          what you can expect to find on talentfind Marketplace
        </div>
        <div class="spacer"></div>
        <div class="row">
          <div class="col-md-4 pr-0">
            <img
              src="/img/tfw/Artboard-3.png"
              width="80"
              sizes="(max-width: 767px) 80px, (max-width: 991px) 10vw, 80px"
              alt=""
              class="feature-icon"
            />
            <div class="description-container">
              <p class="white-paragraph center">
                Create a profile. Publish your jobs<br />Showcased on TalentFind
                World's<br />
                global marketplace
              </p>
            </div>
          </div>
          <div class="col-md-4 pr-0">
            <img
              src="/img/tfw/Artboard-8.png"
              width="74"
              sizes="(max-width: 767px) 80px, (max-width: 991px) 10vw, 80px"
              alt=""
              class="feature-icon"
            />
            <div class="description-container">
              <p class="white-paragraph description center">
                Access to a global talent pool of<br />1000's of qualified
                healthcare<br />professionals
              </p>
            </div>
          </div>
          <div class="col-md-4 pr-0">
            <img
              src="/img/tfw/Artboard-10.png"
              sizes="(max-width: 767px) 80px, (max-width: 991px) 10vw, 80px"
              alt=""
              class="feature-icon"
            />
            <div class="description-container">
              <p class="white-paragraph center">
                Access to a globally connected<br />network.<br />Reduce your
                costs and improve<br />outcomes quickly and easily
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="feature-image">
        <img
          src="/img/tfw/Corporate-Hiring.png"
          width="386"
          sizes="(max-width: 479px) 98vw, 30vw"
          alt=""
          class="image-2"
        />
      </div>
    </div>

    <!-- <div class="wrapper mx-auto mt-4 pt-4">
      <div class="wrap mx-auto">
        <h2 class="text-center text-white">
          For Hiring Organisations. Connecting you globally
        </h2>
        <div class="title text-center text-white">
          what you can expect to find on talentfind Marketplace
        </div>
      </div>
      <div class="row d-flex justify-content-center feature-wrap">
        <div class="col-md-4 feature-content icons">
          <img
            src="/img/tfw/Artboard-3.png"
            width="80"
            sizes="(max-width: 767px) 80px, (max-width: 991px) 10vw, 80px"
            alt=""
            class="feature-icon"
          />
          <div class="description-container">
            <p class="white-paragraph center">
              Access to publish jobs on a global job board
            </p>
          </div>
        </div>
        <div class="col-md-4 feature-content icons">
          <img
            src="/img/tfw//Artboard-8.png"
            width="74"
            sizes="(max-width: 767px) 80px, (max-width: 991px) 10vw, 80px"
            alt=""
            class="feature-icon"
          />
          <div class="description-container">
            <p class="white-paragraph description center">
              Access to a global talentpool
            </p>
          </div>
        </div>
        <div class="col-md-4 feature-content icons">
          <img
            src="/img/tfw//Artboard-10.png"
            sizes="(max-width: 767px) 80px, (max-width: 991px) 10vw, 80px"
            alt=""
            class="feature-icon"
          />
          <div class="description-container">
            <p class="white-paragraph center">
              Access to a global network of healthcare recruitment agencies
            </p>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "TFWSection6",
};
</script>